import { makeAPICall } from "./core";
import Cookies from "js-cookie";
import axios from "axios";

export const TEVService = axios.create(
  {
    baseURL: "/api",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFTOKEN': Cookies.get("csrftoken"),
    },
    maxRedirects: 0,
  }
);

const makePostPayload = (data) => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
};

export const fetchUserContext = () => {
  var url = "/users/current_user/";
  return TEVService.get(url);
};


export const attemptLogin = (formData, navigation) => {
  const url = "/login/";
  const payload = makePostPayload(formData);

  return TEVService
    .post(url, payload)
    .then(() => navigation("/"));
};


export const attemptLogout = (navigation) => {
  const url = "/logout/";
  return TEVService
    .post(url, {})
    .then(() => navigation("/"));
};


export const fetchData = (url, callback) => {
  return makeAPICall(url).then(response => response.json()).then(json => callback(json));
};


export const resourceTypeFormatting = (resourceType) => {
  return resourceType.replace(/([A-Z])/g, ' $1').trim();
};
