import {Collapse, Typography, Row, Col} from 'antd';
import React from 'react';
import { useRouteError } from 'react-router-dom';


// Define default error pages
const Error404 = ({error}) => {
  return (
    <Row>
      <Col>
        <Typography.Title level={3}><b>{error.status}</b>: {error.data}</Typography.Title>
        <Typography.Paragraph>
          Your request could not be found or you do not have permission to view
          this content.
        </Typography.Paragraph>
      </Col>
    </Row>
  );
}

const Error500 = ({error}) => {
  return (
    <Row>
      <Col>
        <Typography.Title level={3}><b>{error.status}</b>: {error.data}</Typography.Title>
        <Typography.Paragraph>
          TEV server returned an error, please try your request after a few
          minutes. If this error returns, please notify a TEV administrator.
        </Typography.Paragraph>
        <Collapse
          items={[
            {
              key: 1,
              label: "Click to show full error", 
              children: (
                <Typography.Paragraph copyable>
                  {JSON.stringify(error)}
                </Typography.Paragraph>
              ),
            }
          ]}
        />
      </Col>
    </Row>
  );
}


export default () => {
  const error = useRouteError();
  switch (error.status) {
    case 404:
      return <Error404 error={error}/>;
    case 500:
      return <Error500 error={error}/>;
    default:
      return (
        <div id="error-page">
          <p>Could not find content</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
          <p>
            {JSON.stringify(error)}
          </p>
        </div>
      );
    }
};
