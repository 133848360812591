import React, {Suspense, useEffect, useState} from 'react';
import {fetchUserContext} from '../tevapi/io';
import { Layout, Menu, Typography } from 'antd';
import { TEVNavLinks } from './navbar.jsx';
import UserContext from "../contexts/user";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingAnimation} from './loading-page';

const { Header, Content, Footer } = Layout;

const TEV2 = ({children}) => {

  const [userContext, setUserContext] = useState({is_staff: false});

  useEffect(() => {
    fetchUserContext().then(response => setUserContext(response.data));
  }, [userContext.pk]);

  return (
    <UserContext.Provider value={userContext}>
      <Suspense fallback={<LoadingAnimation />}>
        <Layout style={{minHeight: "100vh"}}>
          <Header style={{padding: "0"}}>
            <div className="demo-logo" />
            <Menu
              mode="horizontal"
              items={TEVNavLinks(userContext)}
            />
          </Header>
          <Content>
              {children}
          </Content>
          <Footer style={{textAlign: 'center'}}>
            TEV <FontAwesomeIcon icon="fa-regular fa-copyright" /> Apache 2.0 {new Date().getFullYear()}
          </Footer>
        </Layout>
      </Suspense>
    </UserContext.Provider>
  );
};

export default TEV2;
