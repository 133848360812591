import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {attemptLogout} from '../tevapi/io';
import {Typography} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const Logout = () => {
  /**
   * Logs the user out using the TEV API
   */
  const navigation = useNavigate();

  const onClick = (_) => {
    attemptLogout(navigation);
  };

  return (
    <b onClick={onClick}>Log Out</b>
  );
};


const makeAccountMenu = (user) => {
  if (user.is_anonymous || !user.is_authenticated) {
    return {
      key: "login",
      icon: <FontAwesomeIcon icon="fa-regular fa-arrow-right-to-bracket" />,
      label: <NavLink to="/login">Login</NavLink>
    }
  }
  return {
    key: "account",
    label: "Account",
    icon: <FontAwesomeIcon icon="fa-solid fa-user-gear" />,
    children: [
      {
        key: "logout",
        label: <Logout />,
      },
    ],
  }
};


export const TEVNavLinks = (user) => {
  const items = [
    {
      key: "home",
      icon: <FontAwesomeIcon icon="fa-solid fa-house" />,
      label: <NavLink to="/">Home</NavLink>,
    },
    {
      key: "search",
      icon: <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />,
      label: <NavLink to="/search">Search</NavLink>
    }
  ];

  items.push(makeAccountMenu(user));

  if (user.is_staff) {
    items.push({
      key: "admin",
      icon: <FontAwesomeIcon icon="fa-regular fa-screwdriver-wrench" />,
      label: <a href="/admin">Administration</a>
    });
  }
  return items;
};
