import {Viewer, Worker} from '@react-pdf-viewer/core';
import { ThemeContext } from '@react-pdf-viewer/core';
import React, {createContext, useContext, useState} from 'react';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { themePlugin } from '@react-pdf-viewer/theme';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {Button, Image, Progress, Space, Typography} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { ScrollMode, SpecialZoomLevel } from '@react-pdf-viewer/core';

export const FileContext = createContext();

export const FileViewContext = (props) => {
  const [fileUrl, setFileUrl] = useState(null);
  return (
    <FileContext.Provider value={[fileUrl, setFileUrl]}>
      <Worker workerUrl='/static/webpack/js/pdf.worker.min.js'>
        {props.children}
      </Worker>
    </FileContext.Provider>
  );
};

const loadingPage = (percentages) => {
  return (
    <div style={{width: "100%", textAlign: "center", verticalAlign: "center"}}>
      <Space direction='vertical' size="large">
        <Image width="100%" src="/static/icons/tso-badge.svg"/>
        <Progress
          style={{display: "inline-block"}}
          percent={percentages.toPrecision(3)}
        />
      </Space>
    </div>
  );
};

const NullPage = (props) => {
  return (
    <div style={{width: "100%", textAlign: "center", verticalAlign: "center"}}>
      <Space direction='vertical' size="large">
        <Image width="100%" src="/static/icons/tso-badge.svg"/>
        <Typography.Text>{props.loadingText}</Typography.Text>
      </Space>
    </div>
  );
};


const constructErrorPage = ({message, name}) => {
  return (
    <>
      <Typography.Title>{name}</Typography.Title>
      <Image preview={false} width="25%" src="/static/icons/tso-badge.svg"/>
      <Typography.Text>{message}</Typography.Text>
    </>
  );
};

export default () => {
  const [fileUrl, _] = useContext(FileContext);
  const highlightPluginInstance = highlightPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const themePluginInstance = themePlugin();
  const getFilePluginInstance = getFilePlugin();
  const {Toolbar} = toolbarPluginInstance;

  const [currentTheme, setCurrentTheme] = useState('light');
  const themeContext = { currentTheme, setCurrentTheme };

  if (fileUrl === null) {
    return <NullPage loadingText="No Selected PDF" />
  }
  return (
    <ThemeContext.Provider value={themeContext}>
      <Toolbar>
        {
          (props) => {
            const {ZoomIn, ZoomOut, Zoom, Download} = props;
            return (
              <Space>
                <ZoomOut>
                  {(props) => (
                    <Button onClick={props.onClick}>
                      <FontAwesomeIcon icon="fa-regular fa-magnifying-glass-minus" />
                    </Button>
                  )}
                </ZoomOut>
                <Zoom>
                </Zoom>
                <ZoomIn>
                  {(props) => (
                    <Button onClick={props.onClick}>
                      <FontAwesomeIcon icon="fa-regular fa-magnifying-glass-plus" />
                    </Button>
                  )}
                </ZoomIn>
                <Button href={fileUrl} download target="_blank">
                  <FontAwesomeIcon icon="fa-solid fa-download"/>
                </Button>
              </Space>
            );
          }
        }
      </Toolbar>
      <Viewer
        fileUrl={fileUrl}
        plugins={[toolbarPluginInstance, highlightPluginInstance, themePluginInstance, getFilePluginInstance]}
        renderError={constructErrorPage}
        renderLoader={loadingPage}
        theme={currentTheme}
      />
    </ThemeContext.Provider>
  );
};
