import {Row, Col} from 'antd';
import React from 'react';

export const LoadingAnimation = () => {
  return (
    <Row>
      <Col span={12} offset={6}>
        <h4>Loading</h4>
      </Col>
    </Row>
  );
};
