import '../styles/index.scss';
import React, {Suspense} from 'react';
import ErrorPage from './error-page.jsx';
import {createRoot} from 'react-dom/client';
import TEV2 from './components/tev3.jsx';

import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import used icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGear,
  faGreaterThan,
  faLessThan,
  faEquals,
  faPlusMinus,
  faCheck,
  faXmark,
  faHouse,
  faUserGear,
  faArrowLeftLongToLine,
  faPenToSquare,
  faDownload,
  faTrashXmark,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCopyright, faCircleDot, faTable, faScrewdriverWrench, faArrowRightToBracket,
  faTimeline, faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faArrowUpRightFromSquare,
  faSquareQuestion,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faSort, faSortUp, faSortDown
} from '@fortawesome/pro-duotone-svg-icons';

import { FileViewContext } from './components/file-viewer';
import { LoadingAnimation } from './components/loading-page';

// Dynamically import applications for ease of code splitting.
// Generally dynamic imports add lots of complexity to an application,
// however here is a good splitting point since separate views should be
// distinct in user interaction and mindset.
const DetailedItem = React.lazy(
  async () => ({
    default: (await import('./detailed-item')).DetailedItem,
  })
);
const Collection = React.lazy(
  async () => ({
    default: (await import ('./components/collection')).Collection,
  })
);
const Login  = React.lazy(() => import('./login'));
const Vetting = React.lazy(() => import('./vettingApp'));
const CollectionTree = React.lazy(() => import('./components/collections'));
const FeaturedCollections = React.lazy(() => import('./components/featured-collections'));
const Search = React.lazy(() => import('./components/search'));

library.add(faDownload, faTable, faGear, faGreaterThan, faLessThan, faEquals, faPlusMinus, faCheck, faArrowLeftLongToLine, faXmark, faCopyright, faCircleDot, faHouse, faUserGear, faScrewdriverWrench, faArrowRightToBracket, faTimeline, faPenToSquare, faMagnifyingGlass, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faArrowUpRightFromSquare, faSquareQuestion, faSort, faSortUp, faSortDown, faTrashXmark);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <TEV2>
        <FeaturedCollections />
        <CollectionTree></CollectionTree>
      </TEV2>
    ),
    errorElement: <TEV2><ErrorPage /></TEV2>,
  },
  {
    path: "/search",
    element: (
      <TEV2>
        <Search />
      </TEV2>
    ),
  },
  {
    path: "/login",
    element: <Suspense fallback={<LoadingAnimation />}><Login /></Suspense>,
    errorElement: <TEV2><ErrorPage /></TEV2>,
  },
  {
    path: "/collections/:pk",
    element: (
      <TEV2>
        <FileViewContext>
          <Collection />
        </FileViewContext>
      </TEV2>
    ),
    errorElement: <TEV2><ErrorPage /></TEV2>
  },
  {
    path: "/items/:pk",
    element: (
      <TEV2>
        <FileViewContext>
          <DetailedItem />
        </FileViewContext>
      </TEV2>
    ),
    errorElement: <TEV2><ErrorPage /></TEV2>
  },
  {
    path: "/vettings/:pk",
    element: (
      <TEV2>
        <FileViewContext>
          <Vetting />
        </FileViewContext>
      </TEV2>
    ),
    errorElement: <TEV2><ErrorPage /></TEV2>
  },
  // Describe old TEV2 Paths
  {
    path: "/data/search/",
    loader: () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.size == 0) {
        return redirect(`/search/`);
      }
      // Pass along any search parameters
      return redirect(`/search/?${urlParams.toString()}`);
    },
  },
  {
    path: "/data/collection/:pk",
    loader: ({ params }) => redirect(`/collections/${params.pk}`),
  },
  {
    path: "/data/:resource/:pk",
    loader: ({ params}) => redirect(`/items/${params.pk.slice(1)}`),
  },
]);

const client = new QueryClient();

const root = createRoot(document.getElementById('app'));

root.render(
  <QueryClientProvider client={client}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);
